export enum IconType {
  ALERT = 'ALERT',
  ALERT_OUTLINE = 'ALERT_OUTLINE',
  ARROW_RIGHT = 'ARROW_RIGHT',
  BELL = 'BELL',
  BOX_MAGNIFYING = 'BOX_MAGNIFYING',
  CALENDAR = 'CALENDAR',
  CHECK = 'CHECK',
  CHECK_OUTLINE = 'CHECK_OUTLINE',
  CHECKMARK = 'CHECKMARK',
  CHEVRON_UP = 'CHEVRON_UP',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CLEAR = 'CLEAR',
  CLIPBOARD = 'CLIPBOARD',
  CLOSE = 'CLOSE',
  CONTROL_CHECK = 'CONTROL_CHECK',
  DELETE = 'DELETE',
  DELETE_OUTLINE = 'DELETE_OUTLINE',
  DOWNLOAD = 'DOWNLOAD',
  DIAMOND = 'DIAMOND',
  ELLIPSIS = 'ELLIPSIS',
  FILE = 'FILE',
  FILE_DOC = 'FILE_DOC',
  FILE_PDF = 'FILE_PDF',
  FILE_PPT = 'FILE_PPT',
  FILE_XLS = 'FILE_XLS',
  IMAGE = 'IMAGE',
  INFO = 'INFO',
  INFO_OUTLINE = 'INFO_OUTLINE',
  INVALID = 'INVALID',
  LINK = 'LINK',
  LIST_PLUS = 'LIST_PLUS',
  MENU = 'MENU',
  MINUS = 'MINUS',
  PLUS = 'PLUS',
  RESET = 'RESET',
  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  SHOPPING_CART_OUTLINE = 'SHOPPING_CART_OUTLINE',
  SORT_ASC = 'SORT_ASC',
  SORT_DEFAULT = 'SORT_DEFAULT',
  SORT_DESC = 'SORT_DESC',
  SUBTITLE = 'SUBTITLE',
  TRUCK = 'TRUCK',
  QUESTION = 'QUESTION',
  QUESTION_OUTLINE = 'QUESTION_OUTLINE',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
}
