import { cx } from '@emotion/css';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType } from '../../ui/Button';
import { Container } from '../../ui/Container';
import { Icon, IconType } from '../../ui/Icon';
import { baseSpacing } from '../../ui/theme';
import { exhaustiveSwitchCheck } from '../../utils';
import { BulkQuickAddContextProvider } from './BulkQuickAddContext';
import { BulkAddEditableSpreadsheet } from './components/BulkAddEditableSpreadsheet';
import { BulkAddReadOnlySpreadsheet } from './components/BulkAddReadOnlySpreadsheet';
import { DeleteCell } from './components/cells';
import { useBulkQuickAdd } from './hooks/useBulkQuickAdd';
import { deleteTableClassName } from './styles';
import {
  BulkQuickAddContext,
  DataStructure,
  FinalRowItem,
  IProduct,
  RowStatusReason,
} from './types';

type InitialData = Array<{ itemId: number; quantity?: number }>;
type BulkQuickAddProps = {
  products: IProduct[];
  context?: BulkQuickAddContext;
  columnLabels: {
    product: string;
    product_column_info: string;
    quantity: string;
    name: string;
    theme: string;
    filter_availability: string;
    total_pieces: string;
    price_per_piece: string;
    your_price: string;
  };
  availabilityTranslations: {
    available: string;
    low_stock: string;
    expected_back_in_stock: string;
    out_of_stock: string;
    not_available: string;
    bulk_add_product_not_found: string;
    not_in_window: string;
    not_in_promotion: string;
  };
  locale: string;
  onChange?: (items: FinalRowItem[]) => void;
  onRawChange?: (items: Array<{ itemId: number; quantity?: number }>) => void;
  initialData?: InitialData;

  onEditContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  contextMenuToolTipLabel: string;
};

export const BulkQuickAdd: React.FC<BulkQuickAddProps> = ({
  products,
  context = BulkQuickAddContext.REPLENISHMENT,
  columnLabels,
  availabilityTranslations,
  locale,
  onChange,
  onRawChange,
  initialData = [],
  onEditContextMenu,
  contextMenuToolTipLabel,
}) => {
  const [mappedInitialData] = useState(() =>
    initialData.map((row) => [
      {
        value: row.itemId,
      },
      { value: row.quantity },
    ])
  );

  const product_not_found_feedback = useMemo(() => {
    switch (context) {
      case BulkQuickAddContext.REPLENISHMENT:
        return availabilityTranslations.bulk_add_product_not_found;
      case BulkQuickAddContext.LAUNCH:
      case BulkQuickAddContext.LAUNCH_MULTIPLE:
        return availabilityTranslations.not_in_window;
      case BulkQuickAddContext.PROMOTION:
        return availabilityTranslations.not_in_promotion;
      default:
        exhaustiveSwitchCheck(context);
    }
  }, [
    availabilityTranslations.bulk_add_product_not_found,
    availabilityTranslations.not_in_promotion,
    availabilityTranslations.not_in_window,
    context,
  ]);

  const { data, setRawData, removeRow } = useBulkQuickAdd({
    products,
    minimumRows: 50,
    initialData: mappedInitialData,
    product_not_found_feedback,
  });

  const hasIpadPasteButton = useHasFeature('ipad_paste_button', false);
  const handleOnPasteClick = useCallback(async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      const clipboardLines = clipboardText.split('\n');
      const nextRawData: DataStructure['dataRows'] = [];
      const re = /^(\d+)(?:\s+(\d+))?$/m;
      for (const rawClipboardLine of clipboardLines) {
        // Clipboard data on an iPad seems to add \0 in between everything, let's clean it
        const charCodes: number[] = [];
        for (let i = 0; i < rawClipboardLine.length; i++) {
          const charCode = rawClipboardLine.charCodeAt(i);
          if (charCode === 0) {
            continue;
          }
          charCodes.push(rawClipboardLine.charCodeAt(i));
        }
        const clipboardLine = charCodes.map((c) => String.fromCharCode(c)).join('');
        const matches = re.exec(clipboardLine);
        if (!matches) {
          continue;
        }

        const materialId = matches[1] ? parseInt(matches[1], 10) : '';
        const quantity = matches[2] ? parseInt(matches[2], 10) : '';
        nextRawData.push([{ value: materialId }, { value: quantity }]);
      }

      setRawData(nextRawData);
    } catch {
      // Ignore exceptions
    }
  }, [setRawData]);

  useEffect(() => {
    if (onChange) {
      onChange(data.finalRows);
    }

    if (onRawChange) {
      onRawChange(
        data.dataRows.reduce<Array<{ itemId: number; quantity?: number }>>((result, item) => {
          if (!item[0]?.value && !item[1]?.value) {
            return result;
          }

          result.push({
            itemId: item[0]?.value as number,
            quantity: item[1]?.value as number,
          });

          return result;
        }, [])
      );
    }
  }, [onChange, data, onRawChange]);

  return (
    <BulkQuickAddContextProvider
      translations={{ columnLabels, availabilityTranslations }}
      locale={locale}
      rowStatuses={data.statusRows}
      removeRow={removeRow}
    >
      {hasIpadPasteButton && (
        <Container
          padding={{ paddingLeft: 53 }}
          paddingMedium={{
            [media.medium]: {
              paddingLeft: 53,
            },
          }}
        >
          <Button
            type={ButtonType.SECONDARY}
            size={'small'}
            onClick={handleOnPasteClick}
          >
            <Icon type={IconType.CLIPBOARD} />
          </Button>
        </Container>
      )}
      <Container
        flex={{ gap: baseSpacing }}
        padding={{ paddingBottom: baseSpacing * 3 }}
      >
        <BulkAddEditableSpreadsheet
          dataRows={data.dataRows}
          onChange={setRawData}
          onContextMenu={onEditContextMenu}
          contextMenuToolTipLabel={contextMenuToolTipLabel}
        />
        <div css={{ flexGrow: 1 }}>
          <BulkAddReadOnlySpreadsheet data={data.productRows} />
        </div>
        <div>
          <div className={cx(['Spreadsheet', deleteTableClassName])}>
            <table className={'Spreadsheet__table'}>
              <colgroup>
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th className={'Spreadsheet__header'}>&nbsp;</th>
                </tr>
                {data.statusRows.map((rowStatusReason, index) => {
                  // show delete action only if at least one value is set
                  const showDelete =
                    rowStatusReason === RowStatusReason.Valid ||
                    rowStatusReason !== RowStatusReason.None;
                  return (
                    <tr key={`delete-row-${index}`}>
                      <td className={'Spreadsheet__cell'}>
                        {showDelete ? <DeleteCell cell={{ value: index + 1 }} /> : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </BulkQuickAddContextProvider>
  );
};
